import React, { useState, useContext, useCallback, useEffect, useMemo, memo, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import axios from "axios";

import Header from "./Header";
import MainNaviPc from "./MainNaviPc";
import MainNavi from "./MainNavi";
import { today, formatDate, isSameDay, todayYmd } from "../libs/date";
import { useBoolean } from "../hooks/useBoolean";
import { AppContext } from "../AppContext";

const RirekiItem = memo((props) => {
  const { item, onPreviewImages } = props;
  const [meisaiList, setMeisaiList] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [current, setCurrent] = useState(false);
  const isToday = isSameDay(today, item.shoribi);
  const nyukoMeisaiList = useMemo(() => {
    return (meisaiList || [])
      .filter((meisai) => meisai.nyushukkoFlg === 0)
      .map((meisai) => {
        const tmp = (fileList ?? []).find((e) => e.denpyoNo === meisai.denpyoNo) ?? [];
        return { ...meisai, images: tmp.images };
      });
  }, [meisaiList, fileList]);
  const kaishuMeisaiList = useMemo(() => {
    return (meisaiList || [])
      .filter((meisai) => meisai.nyushukkoFlg === 1)
      .map((meisai) => {
        const tmp = (fileList ?? []).find((e) => e.denpyoNo === meisai.denpyoNo) ?? [];
        return { ...meisai, images: tmp.images };
      });
  }, [meisaiList, fileList]);

  const onCurrent = useCallback(async () => {
    try {
      let list = [];
      if (!(meisaiList && meisaiList.length !== 0)) {
        // 入出庫データ取得
        const shohin = document.getElementById("selectShohin") || null;
        // const shohin = null;
        const res = await axios.get("/api/nyushukkarirekidate", {
          params: { ymd: item.shoribi, today: todayYmd, shohinCode: shohin.value },
        });
        if (res && res.data) {
          const { list, files } = res.data;
          setMeisaiList(list);
          setFileList(files);
        }
      }
      setCurrent((prev) => !prev);

      // data無しは自動で閉じる
      // setTimeout(() => {
      //   if (!list.length) {
      //     setCurrent(false);
      //   }
      // }, 500);
    } catch (error) {
      console.warn(error);
    }
  }, [item, meisaiList]);

  return (
    <div className="history__item">
      <a
        href="javascript:void(0);"
        className={`history__item-header ${current && "--current"} ${isToday && "--today"}`}
        onClick={onCurrent}
      >
        <div className="history__item-col">
          <span>{formatDate(item.shoribi, "M月D日(ddd)")}</span>
        </div>
        <div className="history__item-col">
          <span>{item.nyukoSuu.toLocaleString()}</span>
        </div>
        <div className="history__item-col">
          <span>{item.shukoSuu.toLocaleString()}</span>
        </div>
        <div className="history__item-col">
          <span>{item.zaikoSuu.toLocaleString()}</span>
        </div>
        <div className="history__item-col">
          <span className="history__toggle"></span>
        </div>
      </a>
      <CSSTransition in={current} timeout={200} classNames="history-node" unmountOnExit>
        <div className="history__item-body" style={{ display: "block" }}>
          <div className="history-card">
            {nyukoMeisaiList && nyukoMeisaiList.length ? <div className="history-card__title">入庫</div> : ""}
            {nyukoMeisaiList.map((nyuko) => (
              <div key={nyuko.idoId} className="history-card__card">
                <div className="history-card__company">{nyuko.tokuisakiName}</div>
                <div className="history-card__table">
                  <div className="history-card__tr">
                    <div className="history-card__th">パレットの種類</div>
                    <div className="history-card__td">{nyuko.shohinList}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">パレット数</div>
                    <div className="history-card__td">{nyuko.kakuninSuu.toLocaleString()}枚</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">相手先伝票番号</div>
                    <div className="history-card__td">{nyuko.kyakusakiOrder || ""}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">三甲リース伝票番号</div>
                    <div className="history-card__td">{nyuko.denpyoNo}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">車番</div>
                    <div className="history-card__td">{nyuko.shaban}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">写真</div>
                    <div className="history-card__td">
                      {nyuko.images ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            onPreviewImages(nyuko.images);
                          }}
                        >
                          あり
                        </a>
                      ) : (
                        "なし"
                      )}
                    </div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">紐づけ</div>
                    <div className="history-card__td">{nyuko.traxHimodukeCode}</div>
                  </div>
                  {/* 詳細・納品書・添付は一旦非表示 */}
                  {/* <div className="history-card__tr">
                  <div className="history-card__th">詳細</div>
                  <div className="history-card__td">
                    <a href="#" target="_blank" className="link --secondary">
                      会社詳細などリンク
                    </a>
                  </div>
                </div> */}
                  {/* <div className="history-card__tr">
                  <div className="history-card__th">納品書</div>
                  <div className="history-card__td">
                    <a href="#" target="_blank" className="link --secondary">
                      〇〇納品書.jpg
                    </a>
                  </div>
                </div> */}
                  {/* <div className="history-card__tr">
                  <div className="history-card__th">添付</div>
                  <div className="history-card__td">
                    <a href="#" target="_blank" className="link --secondary">
                      △△△△△.pdf
                    </a>
                  </div>
                </div> */}
                </div>
              </div>
            ))}
            {kaishuMeisaiList && kaishuMeisaiList.length ? <div className="history-card__title">出庫</div> : ""}
            {kaishuMeisaiList.map((kaishu) => (
              <div key={kaishu.idoId} className="history-card__card">
                <div className="history-card__company">{kaishu.tokuisakiName}</div>
                <div className="history-card__table">
                  <div className="history-card__tr">
                    <div className="history-card__th">パレットの種類</div>
                    <div className="history-card__td">{kaishu.shohinList}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">パレット数</div>
                    <div className="history-card__td">{kaishu.kakuninSuu.toLocaleString()}枚</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">相手先伝票番号</div>
                    <div className="history-card__td">{kaishu.kyakusakiOrder || ""}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">三甲リース伝票番号</div>
                    <div className="history-card__td">{kaishu.denpyoNo}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">車番</div>
                    <div className="history-card__td">{kaishu.shaban}</div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">写真</div>
                    <div className="history-card__td">
                      {kaishu.images ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            onPreviewImages(kaishu.images);
                          }}
                        >
                          あり
                        </a>
                      ) : (
                        "なし"
                      )}
                    </div>
                  </div>
                  <div className="history-card__tr">
                    <div className="history-card__th">紐づけ</div>
                    <div className="history-card__td">{kaishu.traxHimodukeCode}</div>
                  </div>
                  {/* <div className="history-card__tr">
                  <div className="history-card__th">詳細</div>
                  <div className="history-card__td">
                    <a href="#" target="_blank" className="link --secondary">
                      会社詳細などリンク
                    </a>
                  </div>
                </div> */}
                  {/* <div className="history-card__tr">
                  <div className="history-card__th">納品書</div>
                  <div className="history-card__td">
                    <a href="#" target="_blank" className="link --secondary">
                      〇〇納品書.jpg
                    </a>
                  </div>
                </div> */}
                  {/* <div className="history-card__tr">
                  <div className="history-card__th">添付</div>
                  <div className="history-card__td">
                    <a href="#" target="_blank" className="link --secondary">
                      △△△△△.pdf
                    </a>
                  </div>
                </div> */}
                </div>
              </div>
            ))}
          </div>
          {nyukoMeisaiList && nyukoMeisaiList.length ? (
            <table className="history-table">
              <colgroup>
                <col style={{ width: "160px" }} />
                <col style={{ width: "120px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "120px" }} />
                <col style={{ width: "130px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "60px" }} />
                <col />
              </colgroup>
              <tr className="history-table__header">
                <th className="history-table__th">入庫</th>
                <th className="history-table__th">パレットの種類</th>
                <th className="history-table__th">枚数</th>
                <th className="history-table__th">相手先伝票番号</th>
                <th className="history-table__th">三甲リース伝票番号</th>
                <th className="history-table__th">車番</th>
                <th className="history-table__th">写真</th>
                <th className="history-table__th">紐づけ</th>
                {/* <th className="history-table__th">詳細</th>
                <th className="history-table__th">納品書</th>
                <th className="history-table__th">添付</th> */}
              </tr>
              <tbody className="history-table__body">
                {nyukoMeisaiList.map((nyuko) => (
                  <tr key={nyuko.idoId} className="history-table__tr">
                    <td className="history-table__td">{nyuko.tokuisakiName}</td>
                    <td className="history-table__td">{nyuko.shohinList}</td>
                    <td className="history-table__td">{nyuko.kakuninSuu.toLocaleString()}枚</td>
                    <td className="history-table__td">{nyuko.kyakusakiOrder}</td>
                    <td className="history-table__td">{nyuko.denpyoNo}</td>
                    <td className="history-table__td">{nyuko.shaban}</td>
                    <td className="history-table__td">
                      {nyuko.images ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            onPreviewImages(nyuko.images);
                          }}
                        >
                          あり
                        </a>
                      ) : (
                        "なし"
                      )}
                    </td>
                    <td className="history-table__td">{nyuko.traxHimodukeCode}</td>
                    {/* <td className="history-table__td"><a href="#" class="link --secondary">詳細リンク</a></td>
                    <td className="history-table__td"><a href="#" class="ico --camera"></a></td>
                    <td className="history-table__td"><a href="#" class="ico --clip"></a></td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          {kaishuMeisaiList && kaishuMeisaiList.length ? (
            <table className="history-table">
              <colgroup>
                <col style={{ width: "160px" }} />
                <col style={{ width: "120px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "120px" }} />
                <col style={{ width: "130px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "60px" }} />
                <col />
              </colgroup>
              <tr className="history-table__header">
                <th className="history-table__th">出庫</th>
                <th className="history-table__th">パレットの種類</th>
                <th className="history-table__th">枚数</th>
                <th className="history-table__th">相手先伝票番号</th>
                <th className="history-table__th">三甲リース伝票番号</th>
                <th className="history-table__th">車番</th>
                <th className="history-table__th">写真</th>
                <th className="history-table__th">紐づけ</th>
                {/* <div className="history-table__th">詳細</div> */}
                {/* <div className="history-table__th">納品書</div> */}
                {/* <div className="history-table__th">添付</div> */}
              </tr>
              <tbody className="history-table__body">
                {kaishuMeisaiList.map((kaishu) => (
                  <tr key={kaishu.idoId} className="history-table__tr">
                    <td className="history-table__td">{kaishu.tokuisakiName}</td>
                    <td className="history-table__td">{kaishu.shohinList}</td>
                    <td className="history-table__td">{kaishu.kakuninSuu.toLocaleString()}枚</td>
                    <td className="history-table__td">{kaishu.kyakusakiOrder}</td>
                    <td className="history-table__td">{kaishu.denpyoNo}</td>
                    <td className="history-table__td">{kaishu.shaban}</td>
                    <td className="history-table__td">
                      {kaishu.images ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            onPreviewImages(kaishu.images);
                          }}
                        >
                          あり
                        </a>
                      ) : (
                        "なし"
                      )}
                    </td>
                    <td className="history-table__td">{kaishu.traxHimodukeCode}</td>
                    {/* <td className="history-table__td"><a href="#" class="link --secondary">詳細リンク</a></td>
                    <td className="history-table__td"><a href="#" class="ico --camera"></a></td>
                    <td className="history-table__td"><a href="#" class="ico --clip"></a></td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      </CSSTransition>
      <Helmet>
        <script>
          {`
          .history-node-enter {
            opacity: 0;
            transform: scale(0.9);
          }
          .history-node-enter-active {
            opacity: 1;
            transition: opacity 200ms transform 200ms;
          }
          .history-node-exit {
            opacity: 1;
          }
          .history-node-exit-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 200ms transform 200ms;
          }
          `}
        </script>
      </Helmet>
    </div>
  );
});

const ImageViewer = memo((props) => {
  const { open, control, viewImages } = props;
  console.log(viewImages);
  const [mainImage, setMainImage] = useState(viewImages ? viewImages[0] : "");
  const [modalOpen, modalCotrol] = useBoolean(false);
  return (
    <>
      <div className={`pane pane--kaishubashonoshashin pane--kaishubashonoshashin-exist ${open && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              control.off();
            }}
          ></a>

          <div className="pane__header-title">TRAXの写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic">
                <a href="javascript:void(0);" className="modalPictureOpen" rel={mainImage} onClick={modalCotrol.on}>
                  {mainImage && <img src={mainImage} alt="" />}
                </a>
              </div>
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">写真を選択</div>
              </div>
            </div>
            <div className="pictures --selectable">
              {viewImages?.map((image) => (
                <div key={image} className={`picture ${mainImage === image && "--current"}`}>
                  <a
                    className="picture__pic"
                    onClick={(e) => {
                      e.preventDefault();
                      setMainImage(image);
                    }}
                  >
                    <img src={image} alt="" />
                  </a>
                </div>
              ))}
            </div>

            <div className="btn-layout --stack --w-s"></div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div></div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#888",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              backgroundSize: "contain",
              opacity: modalOpen ? 1 : 0,
            }}
          >
            {mainImage && <img alt="" src={mainImage} />}
          </div>
          <div className="no-print" style={{ position: "absolute", top: 16, right: 16 }}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                modalCotrol.off();
              }}
              style={{ fontSize: 32, color: "#fff" }}
            >
              ✕
            </a>
          </div>
          <div
            className="no-print"
            style={{ position: "absolute", bottom: 32, left: "50%", transform: "translate(-50%, 0)" }}
          >
            <a
              href="#"
              className="btn --primary --h-s"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <span> 印刷する </span>
            </a>
          </div>
        </div>
      )}
    </>
  );
});

export default function NyushukkaRireki() {
  const [data, setData] = useState();
  const [open, control] = useBoolean();
  const [viewImages, setViewImages] = useState();
  const { shohins } = useContext(AppContext);
  const [searchShohin, setSearchShohin] = useState();

  const { rirekis = [], zaikoSuryo } = data || {};

  const selectYear = useRef(null);
  const selectMonth = useRef(null);
  const selectShohin = useRef(null);

  const years = useMemo(() => {
    const listYear = [];
    const nowYear = today.getFullYear();
    for (var i = 2020; i <= nowYear; i++) {
      listYear.push(
        <option value={i.toString()} selected={i === nowYear}>
          {i}年
        </option>
      );
    }
    return listYear;
  }, []);

  const months = useMemo(() => {
    const nowMonth = today.getMonth();
    return [...Array(12).keys()].map((key) => (
      <option key={key.toString()} value={("0" + (key + 1)).slice(-2)} selected={nowMonth === key}>
        {key + 1 + "月"}
      </option>
    ));
  }, []);

  const shohinCodes = useMemo(() => {
    const listShohin = [];
    listShohin.push(
      <option key="-" value="" selected={searchShohin === ""}>
        -
      </option>
    );
    shohins.map((shohin) =>
      listShohin.push(
        <option key={shohin.shohinName} value={shohin.shohinCode} selected={searchShohin === shohin.shohinCode}>
          {shohin.shohinName}
        </option>
      )
    );
    return listShohin;
  }, [shohins, searchShohin]);

  useEffect(() => {
    const FirstData = async () => {
      try {
        // 現在庫と在庫履歴一覧取得
        const ym = (selectYear.current.value || "") + (selectMonth.current.value || "");
        const shohin = selectShohin.current.value || "";
        const res = await axios.get("/api/nyushukkarireki", { params: { ym: ym, ymd: todayYmd, shohinCode: shohin } });
        if (res && res.data) {
          setData(res.data);
        }
      } catch (error) {
        setData({});
        console.error(error);
      }
    };
    if (!!selectYear.current && !!selectMonth.current) {
      FirstData();
    }
  }, [selectYear, selectMonth]);

  const onSearch = useCallback(async (e) => {
    e.preventDefault();

    try {
      // 現在庫と在庫履歴一覧取得
      const ym = (selectYear.current.value || "") + (selectMonth.current.value || "");
      const shohin = selectShohin.current.value || "";
      const res = await axios.get("/api/nyushukkarireki", { params: { ym: ym, ymd: todayYmd, shohinCode: shohin } });
      if (res && res.data) {
        setData(res.data);
      }
    } catch (error) {
      setData({});
      console.error(error);
    }
  }, []);

  const onPreviewImages = (images) => {
    setViewImages(images);
    control.on();
  };

  return (
    <>
      <Header />
      <div className="main">
        <MainNaviPc current="/nyushukka-rireki" />
        <div className="main__main">
          <div className="main__main-header">
            <MainNavi current="/nyushukka-rireki" />
            <div className="hdg --h1">
              <span className="hdg__title">入出荷履歴</span>
              <span className="hdg__zaiko">
                <span>現在の在庫</span>
                <span>{zaikoSuryo?.toLocaleString()}</span>
                <span>枚</span>
              </span>
            </div>
          </div>
          <div className="main__main-contents">
            <div className="inner__">
              <div className="date-selector">
                <select ref={selectShohin} name="" id="selectShohin">
                  {shohinCodes}
                </select>
              </div>
              <div className="date-selector">
                <select ref={selectYear} name="" id="">
                  {years}
                </select>
                <select ref={selectMonth} name="" id="">
                  {months}
                </select>
                <a href="#" className="btn --primary --h-s" onClick={onSearch}>
                  <span>表示</span>
                </a>
              </div>
              <div className="history">
                <div className="history__header">
                  <div className="history__header-col">日付</div>
                  <div className="history__header-col">入庫</div>
                  <div className="history__header-col">回収</div>
                  <div className="history__header-col">在庫</div>
                  <div className="history__header-col"></div>
                </div>
                <div className="history__body">
                  {rirekis.map((rireki) => (
                    <RirekiItem
                      key={rireki.shoribi + rireki.kokyakuId}
                      item={rireki}
                      onPreviewImages={onPreviewImages}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>
      <div className="pane pane--naiyohenko">
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneClose"></a>
          <div className="pane__header-title">内容変更</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__title">
                  <span>回収パレット数</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <input type="text" placeholder="入力(必須)" id="jsText-NumberOfPallets" />
                    </span>
                    <span className="form-detail-layout__unit">枚</span>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0);" className="form-layout paneOpen" rel="parettonoshurui">
                <div className="form-layout__title">
                  <span>パレットの種類</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <span>選択なし</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" className="form-layout paneOpen" rel="kaishukibobi">
                <div className="form-layout__title">
                  <span>回収希望日</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <span>希望なし</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" className="form-layout paneOpen" rel="kaishukibojikan">
                <div className="form-layout__title">
                  <span>回収希望時間</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <span>希望なし</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
            </div>
            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">通知の設定</div>
                <div className="hdg__btn" id="jsType-NotificationType" data-type="mail">
                  <a href="javascript:void(0);" className="btn --gry-secondary --h-xs btn--notification">
                    <span>携帯電話に通知</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__detail">
                  <div className="form-detail-layout__checkbox">
                    <label>
                      <input type="checkbox" className="cbx-notification" name="notification" />
                      <span></span>
                      <span>手配が完了した際に通知を受け取る</span>
                    </label>
                  </div>
                  <div className="form-detail-layout__checkbox">
                    <label>
                      <input type="checkbox" className="cbx-notification" name="notification" />
                      <span></span>
                      <span>車番、ドライバー連絡を受け取る</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-layout form-layout--mail">
                <div className="form-layout__title">
                  <span>メールアドレス</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <input type="text" placeholder="例）irai@pallet-station.com(必須)" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-layout form-layout--mobilephone">
                <div className="form-layout__title">
                  <span>携帯電話番号</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <input type="text" placeholder="例）09000000000(必須)" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">注意事項</div>
              </div>
            </div>
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__full">
                  <textarea
                    name=""
                    placeholder="注意事項等(任意 50文字程度)&#13;&#10;例）回収場所は第2倉庫になります。"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary modalOpen"
                rel="order-done"
                disabled="disabled"
                id="jsSubmit-Kaishuirai"
              >
                <span>内容の変更を依頼する</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="pane pane--kaishukibojikan">
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneOpen" rel="naiyohenko"></a>

          <div className="pane__header-title" id="jsGroup-Kaishukibojikan" data-type="0">
            回収希望時間
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a href="javascript:void(0);" className="btn --wht-secondary btn-group-kaishukibojikan">
                  <span>希望なし</span>
                </a>
              </div>
              <div>
                <a href="javascript:void(0);" className="btn --wht-secondary btn-group-kaishukibojikan">
                  <span>午前　9 - 12時</span>
                </a>
              </div>
              <div>
                <a href="javascript:void(0);" className="btn --wht-secondary btn-group-kaishukibojikan">
                  <span>午後　13 - 17時</span>
                </a>
              </div>
            </div>
            <div className="btn-layout --col-1">
              <div>
                <a
                  href="javascript:void(0);"
                  className="btn --wht-secondary btn-group-kaishukibojikan --h-s --w-ss paneOpen"
                  rel="sonotajikantaishitei"
                >
                  <span>その他時間指定</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneOpen" rel="naiyohenko">
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ImageViewer {...{ open, control, viewImages }} />
    </>
  );
}
