import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
import Tabulator from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import { AppContext } from "../../AppContext";
import { AdminAppContext } from "../../AdminAppContext";
import Header from "./Header";
import Map from "./Map";
import { DayPicker } from "../../components/DayPicker";
import { DateTimePicker } from "../../components/DateTimePicker";
import { formatDate, getDisplayKiboDate, getDisplayKiboTime, toDayjs } from "../../libs/date";
import {
  CANCEL_KBN,
  getMeishoName,
  getMeishos,
  getTeikikaishuLabel,
  TONYA_CHECK_KBN,
  createErrorMessage,
  actionLog,
} from "../../libs/common";
import { useBoolean } from "../../hooks/useBoolean";
import { ErrorModal, DeleteModal, PictureModal, InfoModal } from "../../modals/admin/Dialogs";
import HikitoriModal from "../../modals/admin/HikitoriModal";
import KaishuIraiLeftUpPane from "../../panes/KaishuIraiLeftUpPane";
import TonyaLeftUpPane from "../../panes/TonyaLeftUpPane";
import DepoLeftUpPane from "../../panes/DepoLeftUpPane";
import TeikiKaishuLeftUp from "../../panes/TeikiKaishuLeftUp";
import BinShosaiPane from "../../panes/BinShosaiPane";
import { useKeyDown } from "../../hooks/useKeyDown";

const baseColumns = [
  { title: "!", field: "alert", frozen: true, formatter: "html", width: 30 },
  { title: "依頼日", field: "date", width: 100 },
  { title: "回収店名", field: "company", width: 150 },
  { title: "住所", field: "adress", width: 150 },
  { title: "回収希望日", field: "request", width: 100 },
  { title: "希望時間", field: "time", width: 100 },
  {
    title: "枚数",
    field: "number",
    hozAlign: "right",
    formatter: (cell) => cell.getValue().toLocaleString(),
    width: 100,
  },
  { title: "パレットの種類", field: "pallet", width: 150 },
  { title: "注意事項", field: "biko", width: 150 },
  { title: "M", field: "milnet", frozen: true, formatter: "html", width: 30 },
];

export default function () {
  const { meishos } = useContext(AppContext);
  const {
    setKeiroList,
    isViewBinShosaiPane,
    setViewBinShosaiPane,
    addKeiroListItem,
    hikitoriModalControl,
    cancelIrai,
  } = useContext(AdminAppContext);

  const keyDownRegister = useKeyDown();

  const el = useRef(null);
  const tabulator = useRef(null);
  const mapRef = useRef();

  // grid_columns
  const currentColumnsRef = useRef([]);

  // 名称マスタ
  const ngShakakuList = useMemo(() => getMeishos(meishos, "SHAKAKU_KBN"), [meishos]);
  const kiboShashuList = useMemo(() => getMeishos(meishos, "TONYA_SHASHU_KBN"), [meishos]);
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);
  const youbiList = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);

  // 共通モーダル制御
  const [isPictureModal, pictureModalControl] = useBoolean(false);
  const [isDeleteModal, deleteModalControl] = useBoolean(false);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");
  const refOk = useRef();
  // search state
  const [searchDayFrom, setSearchDayFrom] = useState();
  const [searchDayTo, setSearchDayTo] = useState();
  const [serarchText, setSearchText] = useState("");
  // search state haisha
  const [searchDayHaisha, setSearchDayHaisha] = useState();
  const [searchTextHaisha, setSearchTextHaisha] = useState("");
  // grid選択明細
  const [selectRow, setSelectRow] = useState();
  // 選択中のマーカー明細
  const [selectMarkerIraiItem, setSelectMarkerIraiItem] = useState();
  const [selectMarkerTonyaItem, setSelectMarkerTonyaItem] = useState();
  const [selectMarkerDepoItem, setSelectMarkerDepoItem] = useState();
  const [selectMarkerTeikiItem, setSelectMarkerTeikiItem] = useState();
  const [teikiShosai, setTeikiShosai] = useState();

  // 注文詳細用
  const [binId, setBinId] = useState(null);

  // pane view
  const [isViewKaishuIraiRight, setViewKaishuIraiRight] = useState(false);
  const [isViewTonyaRight, setViewTonyaRight] = useState(false);
  const [isViewDepoRight, setViewDepoRight] = useState(false);
  const [isViewTeikiKaishuRight, setViewTeikiKaishuRight] = useState(false);

  const [isViewKaishuIraiLeftDown, setViewKaishuIraiLeftDown] = useState(false);
  const [isViewTonyaLeftDown, setViewTonyaLeftDown] = useState(false);
  const [isViewDepoLeftDown, setViewDepoLeftDown] = useState(false);
  const [isViewTeikiKaishuLeftDown, setViewTeikiKaishuLeftDown] = useState(false);

  const [isViewNearHaisha, setViewNearhaisha] = useState(false);

  const [isViewKaishuIraiLeftUp, setViewkaishuIraiLeftUp] = useState(false);
  const [isViewTonyaLeftUp, setViewTonyaLeftUp] = useState(false);
  const [isViewDepoLeftUp, setViewDepoLeftUp] = useState(false);
  const [isViewTeikiKaishuLeftUp, setViewTeikiKaishuLeftUp] = useState(false);

  const isViewKohyoLeftUp = useMemo(() => {
    return isViewKaishuIraiLeftUp || isViewTonyaLeftUp || isViewDepoLeftUp || isViewTeikiKaishuLeftUp;
  }, [isViewKaishuIraiLeftUp, isViewTonyaLeftUp, isViewDepoLeftUp, isViewTeikiKaishuLeftUp]);

  // pane内tab control
  const [kaishuIraiPaneTab, setKaishuIraiPaneTab] = useState(0);
  const [tonyaPaneTab, setTonyaPaneTab] = useState(0);
  const [teikiPaneTab, setTeikiPaneTab] = useState(0);
  // table map 表示データ
  const [gridList, setGridList] = useState([]);
  const [haishaList, setHaishaList] = useState([]);
  const [iraiStatus, setIraiStatus] = useState();
  const [binStatusWithTonya, setBinStatusWithTonya] = useState();

  const onRowClick = useCallback(async (e, row, data) => {
    //・依頼内容及び便に関する情報
    // GET /api/irai/{id}
    //・回収店に関する情報（定例回収や現在庫数量)
    // GET /api/tonya/{id}
    //・各テーブルデータ（出荷元、最近の回収実績、入出庫履歴）
    // GET GET /api/tonya-shukkamoto?kokyakuId=1234

    let rowData = data ? data : row.getData();
    const endPoints = [
      { endPoint: "/api/irai/" + rowData.id, dataKbn: "irai" },
      { endPoint: "/api/tonya/" + rowData.kokyakuId, dataKbn: "tonya" },
      {
        endPoint: "/api/tonya-shukkamoto?kokyakuId=" + rowData.kokyakuId,
        dataKbn: "tonyaShukkamoto",
      },
    ];

    try {
      await Promise.all(
        endPoints.map(async ({ endPoint, dataKbn }) => {
          let res = await axios.get(endPoint);
          // irai.bikoとかぶるので削除(備考だけか)
          let tmpData = res.data;
          if (endPoint.substring(0, 11) === "/api/tonya/") {
            tmpData.biko2 = tmpData.biko;
          }
          delete tmpData.biko;
          rowData = { ...rowData, ...tmpData };
        })
      );
      setSelectRow(rowData);
      setViewKaishuIraiLeftDown(true);
      setViewTonyaLeftDown(false);
      setViewDepoLeftDown(false);
      setViewTeikiKaishuLeftDown(false);

      // マーカー押したことにしとく
      setSelectMarkerIraiItem(rowData);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const createTable = useCallback(
    (displayList) => {
      // table set
      if (!el.current) {
        return;
      }
      tabulator.current = new Tabulator(el.current, {
        data: displayList, //link data to table
        columns: currentColumnsRef.current, //define table columns
        rowClick: onRowClick,
        columnResized: (event) => {
          const {
            _column: { width, field },
          } = event;
          const newColumns = [...currentColumnsRef.current].map((col) => {
            if (col.field === field) {
              return { ...col, width };
            }
            return col;
          });
          currentColumnsRef.current = newColumns;
          localStorage.setItem("ps-irai-grid", JSON.stringify(newColumns));
        },
      });
      setGridList(displayList);
    },
    [onRowClick]
  );

  const onSearch = useCallback(
    async (e) => {
      if (!!e) {
        e.preventDefault();
      }
      try {
        const res = await axios.get("/api/irai", {
          params: {
            order: "1",
            from: searchDayFrom && formatDate(searchDayFrom, "YYYY-MM-DD"),
            to: searchDayTo && formatDate(searchDayTo, "YYYY-MM-DD"),
            words: serarchText,
          },
        });
        if (res && res.data) {
          const displayList = res.data.map((v) => {
            v.gridData = true;

            v.type = "kaishuirai";

            if (v.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU) {
              v.subType = "hikitori";
            }

            if (v.henkoFlg === "1" || v.cancelKbn === CANCEL_KBN.CANCELTYU) {
              v.alert = "<img src='/assets/admin/Images/ico_alert.png' width='12' height='12' />";
            }

            v.date = formatDate(v.insertDate, "M/D(ddd) HH:mm");
            v.company = v.name;
            v.adress = v.jusho;
            v.request = getDisplayKiboDate(v.kiboDateFrom, v.kiboDateTo);
            v.time = getDisplayKiboTime(v.kiboTimeFrom, v.kiboTimeTo, v.kiboTimeBiko);
            v.number = v.meisais.reduce((total, m) => total + parseInt(m.suryo || "0"), 0);
            v.pallet = v.meisais.map((x) => x.shohinName).join("、");
            if (v.idoId !== 0) {
              v.milnet =
                "<a href='javascript:alert()'><img src='/assets/admin/Images/ico_milnet.png' width='12' height='12' /></a>";
            }

            v.ngShakakus = getMeishoName(ngShakakuList, v.kiboNgShakakuKbns, "、");
            v.kiboSharyo = getMeishoName(kiboShashuList, v.kiboSharyoKbn);
            v.kiboNiyaku = getMeishoName(niyakuList, v.kiboNiyakuKbn);

            // 引取日,引取時間
            v.hikitoriDate = toDayjs(v.kiboDateTo);
            v.hikitoriTimeText = v.time;

            return v;
          });

          const res2 = await axios.get("/api/irai/status-count");
          if (res2 && res2.data) {
            setIraiStatus(res2.data);
          }
          // table作成
          createTable(displayList);
          // click
          await onRowClick(null, null, displayList[0]);
        }
        await actionLog("依頼一覧 検索", "SUCCESS", null);
      } catch (error) {
        console.warn(error);
        await actionLog("依頼一覧 検索", "ERROR", error.toString());
      }
    },
    [onRowClick, searchDayFrom, searchDayTo, serarchText, createTable, ngShakakuList, kiboShashuList, niyakuList]
  );

  const onSearchHaisha = useCallback(
    async (e) => {
      if (!!e) {
        e.preventDefault();
      }
      try {
        const res = await axios.get("/api/fbin", {
          params: {
            ymd: formatDate(searchDayHaisha, "YYYY-MM-DD"),
            words: searchTextHaisha,
          },
        });
        if (res && res.data) {
          const displayList = res.data.map((v) => {
            v.iraiList = (v.irais || []).map((v2) => {
              // v2.alert = toIraiStatusTag(v2);
              // v2.insertDate = "";
              // v2.suryo = "0";
              // v2.name = v2.name;
              // v2.jusho = v2.jusho;
              // v2.truck = v2.truck;
              return v2;
            });
            v.haishaDate = formatDate(v.haishaDate, "M/D(ddd) HH:mm");
            return v;
          });

          const res2 = await axios.get("/api/irai/status-count");
          if (res2 && res2.data) {
          }
          setHaishaList(displayList);
          await actionLog("依頼一覧 > 近くの配車を探す 検索", "SUCCESS", null);
        }
      } catch (error) {
        console.error(error);
        await actionLog("依頼一覧 > 近くの配車を探す 検索", "ERROR", error.toString());
      }
    },
    [searchTextHaisha, searchDayHaisha]
  );

  const onMarkerClick = useCallback(
    async (item, detail) => {
      // 選択したマーカーデータ+詳細情報を取得
      try {
        if (item.type === "kaishuirai") {
          setSelectMarkerIraiItem(detail);
        }
        if (item.type === "tonya") {
          setSelectMarkerTonyaItem(detail);
        }
        if (item.type === "depo") {
          setSelectMarkerDepoItem(detail);
        }
        if (item.type === "teikikaishu") {
          setSelectMarkerTeikiItem(detail);
          const res = await axios.get("/api/tonya-shukkamoto?kokyakuId=" + item.kokyakuId);
          setTeikiShosai(res?.data);
        }

        if (isViewBinShosaiPane) {
          setViewkaishuIraiLeftUp(item.type === "kaishuirai");
          setViewTonyaLeftUp(item.type === "tonya");
          setViewDepoLeftUp(item.type === "depo");
          setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
        } else {
          setViewKaishuIraiLeftDown(item.type === "kaishuirai");
          setViewTonyaLeftDown(item.type === "tonya");
          setViewDepoLeftDown(item.type === "depo");
          setViewTeikiKaishuLeftDown(item.type === "teikikaishu");
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [isViewBinShosaiPane]
  );

  const onClickNewChumon = useCallback(
    (e) => {
      e.preventDefault();
      setTimeout(() => setViewKaishuIraiLeftDown(false), 0);
      setKeiroList([selectMarkerIraiItem]);
      setViewBinShosaiPane(true);
    },
    [selectMarkerIraiItem, setKeiroList, setViewBinShosaiPane]
  );

  const onClickIraiCancel = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await cancelIrai(selectMarkerIraiItem?.id);
        setViewKaishuIraiLeftDown(false);
        const newList = gridList.filter((e) => e.id !== selectMarkerIraiItem?.id);
        createTable(newList);
      } catch (error) {
        console.warn(error);
      }
    },
    [cancelIrai, selectMarkerIraiItem, createTable, gridList]
  );

  const onAddKeiroList = useCallback(
    (e, item) => {
      e.preventDefault();

      try {
        addKeiroListItem(item);

        setViewkaishuIraiLeftUp(false);
        setViewTonyaLeftUp(false);
        setViewDepoLeftUp(false);
        setViewTeikiKaishuLeftUp(false);
      } catch (error) {
        const message = createErrorMessage(error);
        setMessage(message);
        errorModalControl.on();
      }
    },
    [addKeiroListItem, errorModalControl]
  );

  const onDeleteKeiroList = useCallback(
    (e, item) => {
      e.preventDefault();
      setKeiroList((prev) => prev.filter((x) => x.id !== item.id));

      setViewkaishuIraiLeftUp(false);
      setViewTonyaLeftUp(false);
      setViewDepoLeftUp(false);
      setViewTeikiKaishuLeftUp(false);
    },
    [setKeiroList]
  );

  const onClickDetail = useCallback(async (e, item) => {
    e.preventDefault();
    if (item.type === "kaishuirai") {
      setSelectMarkerIraiItem(item);
    } else if (item.type === "tonya") {
      setSelectMarkerTonyaItem(item);
    } else if (item.type === "depo") {
      setSelectMarkerDepoItem(item);
    } else if (item.type === "teikikaishu") {
      setSelectMarkerTeikiItem(item);
      const res = axios.get("/api/tonya-shukkamoto?kokyakuId=" + item.kokyakuId);
      setTeikiShosai(res);
      console.log(res);
    }
    setViewkaishuIraiLeftUp(item.type === "kaishuirai");
    setViewTonyaLeftUp(item.type === "tonya");
    setViewDepoLeftUp(item.type === "depo");
    setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
  }, []);

  const onOpenPictureModal = useCallback(
    (e) => {
      e.preventDefault();
      pictureModalControl.on();
    },
    [pictureModalControl]
  );

  const onHikitoriKakutei = useCallback(async () => {
    try {
      refOk.current = async (e) => {
        e.preventDefault();
        const f = { tonyaCheckKbn: TONYA_CHECK_KBN.OK };
        const res = await axios.put("/api/irai/" + selectMarkerIraiItem.id, f);
        setSelectMarkerIraiItem((pre) => {
          return { ...pre, subType: "", ...f };
        });
        infoModalControl.off();
      };
      setMessage("引取依頼を確定しますか？");
      infoModalControl.on();
    } catch (error) {
      console.error(error);
    }
  }, [selectMarkerIraiItem, infoModalControl]);

  useEffect(() => {
    try {
      const columnsString = localStorage.getItem("ps-irai-grid");
      const columns = !!columnsString ? JSON.parse(columnsString) : baseColumns;
      currentColumnsRef.current = columns;
    } catch (error) {
      console.error(error);
      currentColumnsRef.current = baseColumns;
    }
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var hoyuritu = (selectMarkerDepoItem?.zaikoSuryo / selectMarkerDepoItem?.hoyuKanoSuu) * 100;
  hoyuritu = isNaN(hoyuritu) ? 0 : hoyuritu;
  hoyuritu = isFinite(hoyuritu) ? hoyuritu : 0;

  return (
    <div className="wrapper">
      <Header eventF12={onSearch} eventF6={() => mapRef.current?.reload()} />
      <div className="main">
        <div className="main-inner">
          <div className="hdg">
            <div className="hdg__text">依頼一覧</div>
          </div>
          <div className="search --border">
            <div className="search__condition">
              <div className="condition">
                <div className="condition__date">
                  {/* <input type="text" className="w--110 datepicker hasDatepicker" id="dp1633314182803" /> */}
                  <DateTimePicker
                    day={searchDayFrom}
                    setDay={setSearchDayFrom}
                    tabIndex={1}
                    keyDownRegister={keyDownRegister}
                  />
                  &nbsp;～&nbsp;
                  {/* <input type="text" className="w--110 datepicker hasDatepicker" id="dp1633314182804" /> */}
                  <DateTimePicker
                    day={searchDayTo}
                    setDay={setSearchDayTo}
                    tabIndex={2}
                    keyDownRegister={keyDownRegister}
                  />
                </div>
                <div className="condition__freeword">
                  <input
                    type="text"
                    className="w--180 ico --search --s --offset"
                    placeholder="回収店・商品名"
                    onChange={(e) => setSearchText(e.target.value)}
                    {...keyDownRegister(3)}
                  />
                </div>
                <div className="condition__btn">
                  <a href="#" className="btn --secondary --h-s w--80" onClick={onSearch} {...keyDownRegister(4)}>
                    <span>検索</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="search__result">
              <div className="result">
                <div className="result__new">
                  <span>新規依頼：</span>
                  <span>{iraiStatus?.iraityuNum?.toLocaleString()}</span>
                </div>
                <div className="result__change">
                  <span>変更依頼：</span>
                  <span>{iraiStatus?.henkoiraiNum?.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>
          <div id="list" ref={el} />
        </div>
      </div>

      <Map
        ref={mapRef}
        markerList={gridList}
        {...{
          selectRow,
          onMarkerClick,
          isViewKohyoLeftUp,
        }}
      />

      <div className={`pane pane--right pane--kaishuirai-right ${isViewKaishuIraiRight && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --kaishuirai">{selectMarkerIraiItem?.id}回収依頼</div>
            <div className="hdg__status">
              {selectMarkerIraiItem?.henkoFlg === "1" && (
                <div className="status --attention --exclamation">
                  <span>変更依頼</span>
                  {selectMarkerIraiItem?.date}
                </div>
              )}
            </div>
            <a
              href="#"
              className="hdg__close paneClose mapClose"
              rel="kaishuirai-right"
              onClick={(e) => {
                e.preventDefault();
                setViewKaishuIraiRight(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">PALSAK名古屋</div>
                <div className="title__zaiko">
                  現在の在庫
                  <span>{selectMarkerIraiItem?.zaikoSuryo?.toLocaleString()}</span>枚
                </div>
              </div>
              <div className="requests --normal">
                <div className="request">
                  <div className="request__title">回収パレット数</div>
                  <div className="request__value">
                    <span>{selectMarkerIraiItem?.number?.toLocaleString()}</span>枚
                  </div>
                </div>
                <div className="request">
                  <div className="request__title">回収希望日</div>
                  <div className="request__value">{selectMarkerIraiItem?.request}</div>
                </div>
                <div className="request">
                  <div className="request__title">回収希望時間</div>
                  <div className="request__value">{selectMarkerIraiItem?.time}</div>
                </div>
                <div className="request">
                  <div className="request__title">パレットの種類</div>
                  <div className="request__value">{selectMarkerIraiItem?.pallet}</div>
                </div>
                <div className="request">
                  <div className="request__title">NG車格</div>
                  <div className="request__value">{selectMarkerIraiItem?.ngShakakus}</div>
                </div>
                <div className="request">
                  <div className="request__title">希望車種</div>
                  <div className="request__value">{selectMarkerIraiItem?.kiboSharyo}</div>
                </div>
                <div className="request" hidden>
                  <div className="request__title">希望荷役</div>
                  <div className="request__value">{selectMarkerIraiItem?.kiboNiyaku}</div>
                </div>
                <div className="request">
                  <div className="request__title">注意事項</div>
                  <div
                    className="request__value"
                    dangerouslySetInnerHTML={{
                      __html: selectMarkerIraiItem?.biko?.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
                <div className="request">
                  <div className="request__title ico --milnet">MILNET番号</div>
                  <div className="request__value">{selectMarkerIraiItem?.ido?.denpyoNo}</div>
                </div>
                <div className="request">
                  <div className="request__title">回収店備考</div>
                  <div
                    className="request__value"
                    dangerouslySetInnerHTML={{ __html: selectMarkerIraiItem?.biko2?.replace(/\n/g, "<br />") }}
                  />
                </div>
              </div>
              <div className="collection-place --border">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">{`定期回収 [ ${getMeishoName(
                    youbiList,
                    selectMarkerIraiItem?.user?.kaishuYoubiKbns
                  )} ]`}</div>
                  <div className="ico --s --location">{selectMarkerIraiItem?.jusho}</div>
                  <div className="ico --s --tel">{selectMarkerIraiItem?.tel || "－"}</div>
                  <div className="ico --s --driver">{selectMarkerIraiItem?.tanto || "－"}</div>
                </div>
                <div className="collection-place__pic --noimage">
                  {!!selectMarkerIraiItem?.files && (
                    <a href="javascript:void(0);" className="jsShowPicture">
                      <img src={selectMarkerIraiItem?.files[0]?.url} alt="" />
                    </a>
                  )}
                </div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, selectMarkerIraiItem?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    {getDisplayKiboTime(
                      selectMarkerIraiItem?.user?.kaishuTimeFrom,
                      selectMarkerIraiItem?.user?.kaishuTimeTo
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>{selectMarkerIraiItem?.user?.hokanGendo}枚</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span>{getMeishoName(niyakuList, selectMarkerIraiItem?.user?.niyakuKbn)}</span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span
                    className="confirm-layout"
                    dangerouslySetInnerHTML={{
                      __html: selectMarkerIraiItem?.user?.biko?.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              </div>
              <div className="tab --kaishuirai">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 0 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(0);
                      }}
                    >
                      出荷元
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 1 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(1);
                      }}
                    >
                      最近の回収実績
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 2 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(2);
                      }}
                    >
                      入出庫履歴
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table
                    className="tab__table"
                    style={{
                      display: kaishuIraiPaneTab === 0 ? "table" : "none",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    {selectMarkerIraiItem?.shukkamotos.map((item, index) => (
                      <tr className="tab__tr" key={"shukkamoto-" + index.toString()}>
                        <td>{item.tokuisakiName}</td>
                        <td>{item.shohinName}</td>
                        <td className="ta--r">{item.mc}</td>
                        <td className="ta--r">{item.pc}</td>
                      </tr>
                    ))}
                  </table>
                  <table
                    className="tab__table"
                    style={{
                      display: kaishuIraiPaneTab === 1 ? "table" : "none",
                    }}
                  >
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    {selectMarkerIraiItem?.kaishujissekis?.map((item, index) => (
                      <tr className="tab__tr" key={"kaishujissekis-" + index.toString()}>
                        <td>{formatDate(item.kaishuBi, "M/D(ddd)")}</td>
                        <td>{item.unsoName}</td>
                        <td>{item.jippiUnchinSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.kaishuKingaku.toLocaleString()}円</td>
                      </tr>
                    ))}
                  </table>
                  <table
                    className="tab__table"
                    style={{
                      display: kaishuIraiPaneTab === 2 ? "table" : "none",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    {selectMarkerIraiItem?.nyushukkorirekis.map((item, index) => (
                      <tr className="tab__tr" key={"nyushukkorirekis-" + index.toString()}>
                        <td>{formatDate(item.shoribi, "M/D(ddd)")}</td>
                        <td className="ta--r">{item.nyukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.shukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.zaikoSuu.toLocaleString()}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--right pane--tonya-right ${isViewTonyaRight && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --tonya">回収店</div>
            <a
              href="#"
              className="hdg__close paneClose mapClose"
              rel="tonya-right"
              onClick={(e) => {
                e.preventDefault();
                setViewTonyaRight(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{selectMarkerTonyaItem?.name}</div>
                <div className="title__zaiko">
                  現在の在庫
                  <span>{selectMarkerTonyaItem?.zaikoSuryo?.toLocaleString() || "-"}</span>枚
                </div>
              </div>
              <div className="requests --normal">
                <div className="request">
                  <div className="request__title">引取依頼最終送信日</div>
                  <div className="request__value">{formatDate(selectMarkerTonyaItem?.updateDate, "YYYY/M/D")}</div>
                </div>
                <div className="request">
                  <div className="request__title">最終更新者</div>
                  <div className="request__value">三甲リース 物流管理部　{selectMarkerTonyaItem?.updateLid}</div>
                </div>
                <div className="request">
                  <div className="request__title">社内備考</div>
                  <div className="request__value">{selectMarkerTonyaItem?.biko}</div>
                </div>
              </div>
              <div className="btn-layout --new">
                <a href="javascript:void(0);" className="btn --secondary jsShowHikitoriirai" disabled="disabled">
                  <span>引取依頼確認中</span>
                </a>
              </div>
              <div className="collection-place --border">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                    selectMarkerTonyaItem?.teikis,
                    youbiList
                  )} ]`}</div>
                  <div className="ico --s --location">{selectMarkerTonyaItem?.jusho}</div>
                  <div className="ico --s --tel">{selectMarkerTonyaItem?.tel}</div>
                  <div className="ico --s --driver">{selectMarkerTonyaItem?.tanto}</div>
                </div>
                <div className="collection-place__pic --noimage"></div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, selectMarkerTonyaItem?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    <span>午前</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>150枚</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span>－</span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span>10トン社での搬入の場合は、事前に担当者にご連絡をお願いします。</span>
                </div>
              </div>
              <div className="tab --tonya">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a
                      href="javascript:void(0);"
                      className={tonyaPaneTab === 0 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTonyaPaneTab(0);
                      }}
                    >
                      出荷元
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={tonyaPaneTab === 1 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTonyaPaneTab(1);
                      }}
                    >
                      最近の回収実績
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={tonyaPaneTab === 2 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTonyaPaneTab(2);
                      }}
                    >
                      入出庫履歴
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table" style={{ display: tonyaPaneTab === 0 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    {selectMarkerTonyaItem?.shukkamotos?.map((shukkamoto, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>花王</td>
                        <td>R2-11LX</td>
                        <td className="ta--r">4.4円×12日</td>
                        <td className="ta--r">250円</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: tonyaPaneTab === 1 ? "table" : "none" }}>
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    {selectMarkerTonyaItem?.kaishujissekis?.map((kaishujisseki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{formatDate(kaishujisseki.kaishuBi, "M/D(ddd)")}</td>
                        <td>{kaishujisseki.unsoName}</td>
                        <td>{kaishujisseki.jippiUnchinSuu.toLocaleString()}</td>
                        <td className="ta--r">{kaishujisseki.kaishuKingaku.toLocaleString()}円</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: tonyaPaneTab === 2 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    {selectMarkerTonyaItem?.nyushukkorirekis?.map((nyushukkorireki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>6/15(月)</td>
                        <td className="ta--r">20</td>
                        <td className="ta--r">0</td>
                        <td className="ta--r">20</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--right pane--depo-right ${isViewDepoRight && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --depo">デポ</div>
            <a href="javascript:void(0);" className="hdg__close paneClose mapClose" rel="depo-right">
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{selectMarkerDepoItem?.name}</div>
              </div>
              <div className="requests --normal --fz-s">
                <div className="request">
                  <div className="request__title">住所</div>
                  <div className="request__value">{selectMarkerDepoItem?.jusho}</div>
                </div>
                <div className="request">
                  <div className="request__title">電話番号</div>
                  <div className="request__value">{selectMarkerDepoItem?.tel}</div>
                </div>
                <div className="request">
                  <div className="request__title">営業時間</div>
                  <div className="request__value">{selectMarkerDepoItem?.eigyoJikan}</div>
                </div>
                <div className="request">
                  <div className="request__title">休日対応</div>
                  <div className="request__value">{selectMarkerDepoItem?.kyujitsuTaio}</div>
                </div>
                <div className="request">
                  <div className="request__title">洗浄機能</div>
                  <div className="request__value">{selectMarkerDepoItem?.senjo}</div>
                </div>
                <div className="request">
                  <div className="request__title">検品数</div>
                  <div className="request__value">{selectMarkerDepoItem?.kenpin}</div>
                </div>
                <div className="request">
                  <div className="request__title">洗浄数</div>
                  <div className="request__value">{selectMarkerDepoItem?.senjosu}</div>
                </div>
              </div>
              <div className="note">
                <div>
                  <span>備考1：</span>
                  <span>{selectMarkerDepoItem?.depoBiko}</span>
                </div>
                <div>
                  <span>備考2：</span>
                  <span>{selectMarkerDepoItem?.psBiko}</span>
                </div>
              </div>
              <div className="owned-pallets">
                <div className="owned-pallets__title">現在の保管枚数</div>
                <div className="owned-pallets__body">
                  <div>
                    <span>
                      <span>{selectMarkerDepoItem?.zaikoSuryo?.toLocaleString() || "－"}</span>
                      枚/
                      {selectMarkerDepoItem?.hoyuKanoSuu?.toLocaleString() || "－"}枚
                    </span>
                    <span>{`(${
                      !selectMarkerDepoItem || hoyuritu === 0 ? "－" : hoyuritu?.toLocaleString() || "－"
                    }%)`}</span>
                  </div>
                  <div>
                    <span>
                      <span
                        style={{
                          width: hoyuritu >= 100 ? 100 + "%" : (hoyuritu || 0) + "%",
                        }}
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="tab --depo">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a href="javascript:void(0);" className="--current">
                      今後１週間の予定
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫予定数</th>
                      <th>出庫予定数</th>
                      <th>予定保管枚数(使用率)</th>
                    </tr>
                    <tr className="tab__tr">
                      <td>09/07(火)</td>
                      <td className="ta--r">0</td>
                      <td className="ta--r">600</td>
                      <td className="ta--r">1,000(20%)</td>
                    </tr>
                    <tr className="tab__tr">
                      <td>09/07(火)</td>
                      <td className="ta--r">1,000</td>
                      <td className="ta--r">600</td>
                      <td className="ta--r">1,000(20%)</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--right pane--teikikaishu ${isViewTeikiKaishuRight && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --teikikaishu">定期回収</div>
            <a href="javascript:void(0);" className="hdg__close paneClose mapClose" rel="teikikaishu">
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{selectMarkerTeikiItem?.name}</div>
                <div className="title__zaiko">
                  現在の在庫
                  <span>{selectMarkerTeikiItem?.zaikoSuryo?.toLocaleString() || "－"}</span>枚
                </div>
              </div>
              <div className="collection-place">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">定期回収 [ 月、木 午前中 ]</div>
                  <div className="ico --s --location">{selectMarkerTeikiItem?.jusho}</div>
                  <div className="ico --s --tel">052-551-6121</div>
                  <div className="ico --s --driver">加藤武弘</div>
                </div>
                <div className="collection-place__pic --noimage">
                  <a href="javascript:void(0);" className="jsShowPicture">
                    <img src="https://dummyimage.com/140x96/ccc/fff" alt="" />
                  </a>
                </div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, selectMarkerTeikiItem?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    <span>午前中</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>150枚</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span>－</span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span>10トン社での搬入の場合は、事前に担当者にご連絡をお願いします。</span>
                </div>
              </div>
              <div className="tab --teikikaishu">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a href="javascript:void(0);" className="--current">
                      出荷元
                    </a>
                    <a href="javascript:void(0);">最近の回収実績</a>
                    <a href="javascript:void(0);">入出庫履歴</a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table">
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    <tr className="tab__tr">
                      <td>花王</td>
                      <td>R2-11LX</td>
                      <td className="ta--r">4.4円×12日</td>
                      <td className="ta--r">250円</td>
                    </tr>
                  </table>
                  <table className="tab__table">
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    <tr className="tab__tr">
                      <td>6/15(月)</td>
                      <td>中部急送</td>
                      <td>123</td>
                      <td className="ta--r">478円/枚</td>
                    </tr>
                    <tr className="tab__tr">
                      <td>6/15(月)</td>
                      <td>中部急送</td>
                      <td className="ta--r">478円/枚</td>
                    </tr>
                  </table>
                  <table className="tab__table">
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    <tr className="tab__tr">
                      <td>6/15(月)</td>
                      <td className="ta--r">20</td>
                      <td className="ta--r">0</td>
                      <td className="ta--r">20</td>
                    </tr>
                    <tr className="tab__tr">
                      <td>6/15(月)</td>
                      <td className="ta--r">20</td>
                      <td className="ta--r">0</td>
                      <td className="ta--r">20</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--left-down pane--kaishuirai-left-down ${isViewKaishuIraiLeftDown && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --kaishuirai">回収依頼</div>
            <div className="hdg__status">
              {selectMarkerIraiItem?.henkoFlg === "1" && (
                <div className="status --attention --exclamation">
                  <span>変更依頼</span>
                  {selectMarkerIraiItem?.date}
                </div>
              )}
              {selectMarkerIraiItem?.cancelKbn === CANCEL_KBN.CANCELTYU && (
                <div className="status --attention">
                  <span>キャンセル依頼</span>
                </div>
              )}
              {selectMarkerIraiItem?.tonyaCheckKbn === TONYA_CHECK_KBN.NG && (
                <div className="status --attention">
                  <span>回収店NG</span>
                </div>
              )}
              {selectMarkerIraiItem?.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU && (
                <div className="status --attention">
                  <span>引取依頼確認中</span>
                </div>
              )}
            </div>
            <a
              href="#"
              className="hdg__close paneClose mapClose"
              rel="kaishuirai-left-down"
              onClick={(e) => {
                e.preventDefault();
                setSelectRow(null);
                setViewKaishuIraiLeftDown(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{selectMarkerIraiItem?.name}</div>
                <div className="title__zaiko">
                  現在の在庫
                  <span>{selectMarkerIraiItem?.zaikoSuryo?.toLocaleString() || "－"}</span>枚
                </div>
              </div>
              <div className="requests --normal">
                <div className="request">
                  <div className="request__title">回収パレット数</div>
                  <div className="request__value">
                    <span>{selectMarkerIraiItem?.number?.toLocaleString()}</span>枚
                  </div>
                </div>
                <div className="request">
                  <div className="request__title">回収希望日</div>
                  <div className="request__value">{selectMarkerIraiItem?.request}</div>
                </div>
                <div className="request">
                  <div className="request__title">回収希望時間</div>
                  <div className="request__value">{selectMarkerIraiItem?.time}</div>
                </div>
                <div className="request">
                  <div className="request__title">パレットの種類</div>
                  <div className="request__value">{selectMarkerIraiItem?.pallet}</div>
                </div>
                <div className="request">
                  <div className="request__title">NG車格</div>
                  <div className="request__value">{selectMarkerIraiItem?.ngShakakus}</div>
                </div>
                <div className="request">
                  <div className="request__title">希望車種</div>
                  <div className="request__value">{selectMarkerIraiItem?.kiboSharyo}</div>
                </div>
                <div className="request" hidden>
                  <div className="request__title">希望荷役</div>
                  <div className="request__value">{selectMarkerIraiItem?.kiboNiyaku}</div>
                </div>
                <div className="request">
                  <div className="request__title">注意事項</div>
                  <div
                    className="request__value"
                    dangerouslySetInnerHTML={{
                      __html: selectMarkerIraiItem?.biko?.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
                <div className="request">
                  <div className="request__title ico --milnet">MILNET番号</div>
                  <div className="request__value">{selectMarkerIraiItem?.ido?.denpyoNo}</div>
                </div>
                <div className="request">
                  <div className="request__title">回収店備考</div>
                  <div
                    className="request__value"
                    dangerouslySetInnerHTML={{ __html: selectMarkerIraiItem?.biko2?.replace(/\n/g, "<br />") }}
                  />
                </div>

                <div className="btn-layout --new">
                  {selectMarkerIraiItem?.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU && (
                    <a
                      href="#"
                      class="btn --secondary --w-l pr--0 jsShowHikitoriirai"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <span>引取依頼を変更</span>
                      <span class="btn__change">
                        <div class="btn__list">
                          <span onClick={hikitoriModalControl.on}>引取依頼を編集</span>
                          <span onClick={onHikitoriKakutei}>引取依頼を確定</span>
                        </div>
                      </span>
                    </a>
                  )}
                </div>
              </div>
              <div className="collection-place --border">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                    selectMarkerIraiItem?.teikis,
                    youbiList
                  )} ]`}</div>
                  <div className="ico --s --location">{selectMarkerIraiItem?.jusho}</div>
                  <div className="ico --s --tel">{selectMarkerIraiItem?.tel || "－"}</div>
                  <div className="ico --s --driver">{selectMarkerIraiItem?.tanto || "－"}</div>
                </div>
                <div className={`collection-place__pic ${selectMarkerIraiItem?.files?.length === 0 && "--noimage"}`}>
                  {selectMarkerIraiItem?.files?.length > 0 && (
                    <a href="#" className="jsShowPicture" onClick={onOpenPictureModal}>
                      <img src={selectMarkerIraiItem?.files[0]?.url} alt="" />
                    </a>
                  )}
                </div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, selectMarkerIraiItem?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    <span>
                      {getDisplayKiboTime(
                        selectMarkerIraiItem?.user?.kaishuTimeFrom,
                        selectMarkerIraiItem?.user?.kaishuTimeTo
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>{selectMarkerIraiItem?.user?.hokanGendo}枚</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span>{getMeishoName(niyakuList, selectMarkerIraiItem?.user?.niyakuKbn)}</span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span
                    className="confirm-layout"
                    dangerouslySetInnerHTML={{
                      __html: selectMarkerIraiItem?.user?.biko?.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              </div>
              <div className="tab --kaishuirai">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 0 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(0);
                      }}
                    >
                      出荷元
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 1 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(1);
                      }}
                    >
                      最近の回収実績
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 2 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(2);
                      }}
                    >
                      入出庫履歴
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table
                    className="tab__table"
                    style={{
                      display: kaishuIraiPaneTab === 0 ? "table" : "none",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    {selectMarkerIraiItem?.shukkamotos?.map((item, index) => (
                      <tr className="tab__tr" key={"shukkamoto-" + index.toString()}>
                        <td>{item.tokuisakiName}</td>
                        <td>{item.shohinName}</td>
                        <td className="ta--r">{item.mc}</td>
                        <td className="ta--r">{item.pc}</td>
                      </tr>
                    ))}
                  </table>
                  <table
                    className="tab__table"
                    style={{
                      display: kaishuIraiPaneTab === 1 ? "table" : "none",
                    }}
                  >
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    {selectMarkerIraiItem?.kaishujissekis.map((item, index) => (
                      <tr className="tab__tr" key={"kaishujissekis-" + index.toString()}>
                        <td>{formatDate(item.kaishuBi, "M/D(ddd)")}</td>
                        <td>{item.unsoName}</td>
                        <td>{item.jippiUnchinSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.kaishuKingaku.toLocaleString()}円</td>
                      </tr>
                    ))}
                  </table>
                  <table
                    className="tab__table"
                    style={{
                      display: kaishuIraiPaneTab === 2 ? "table" : "none",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    {selectMarkerIraiItem?.nyushukkorirekis?.map((item, index) => (
                      <tr className="tab__tr" key={"nyushukkorirekis-" + index.toString()}>
                        <td>{formatDate(item.shoribi, "M/D(ddd)")}</td>
                        <td className="ta--r">{item.nyukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.shukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.zaikoSuu.toLocaleString()}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pane__footer">
          <div className="btn-layout --center">
            {selectMarkerIraiItem?.cancelKbn === CANCEL_KBN.CANCELTYU ? (
              <a href="#" className="btn --primary paneOpen mapClose" rel="shinkichumon" onClick={onClickIraiCancel}>
                <span>承諾する</span>
              </a>
            ) : (
              <>
                <a href="#" className="btn --primary paneOpen mapClose" rel="shinkichumon" onClick={onClickNewChumon}>
                  <span>新規注文を作成</span>
                </a>
                <a
                  href="#"
                  className="btn --primary paneOpen mapClose"
                  rel="chikakunohaisha"
                  onClick={(e) => {
                    e.preventDefault();
                    setViewNearhaisha(true);
                  }}
                >
                  <span>近くの配車を探す</span>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`pane pane--left-down pane--tonya-left-down ${isViewTonyaLeftDown && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --tonya">回収店</div>
            <a
              href="#"
              className="hdg__close paneClose mapClose"
              rel="tonya-left-down"
              onClick={(e) => {
                e.preventDefault();
                setSelectMarkerTonyaItem(null);
                setViewTonyaLeftDown(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <span>
                  <ul>
                    <li>{selectMarkerTonyaItem?.id}</li>
                    <li>
                      <span className="title__title">{selectMarkerTonyaItem?.name}</span>
                    </li>
                  </ul>
                </span>
                <div className="title__zaiko">
                  現在の在庫
                  <span>{selectMarkerTonyaItem?.zaikoSuryo?.toLocaleString() || "-"}</span>枚
                </div>
              </div>
              <div className="requests --normal">
                <div className="request">
                  <div className="request__title">引取依頼最終送信日</div>
                  <div className="request__value">{formatDate(selectMarkerTonyaItem?.updateDate, "YYYY/M/D")}</div>
                </div>
                <div className="request">
                  <div className="request__title">最終更新者</div>
                  <div className="request__value">{selectMarkerTonyaItem?.updateLid}</div>
                </div>
                <div className="request">
                  <div className="request__title">社内備考</div>
                  <div className="request__value">{selectMarkerTonyaItem?.biko}</div>
                </div>
              </div>
              <div className="btn-layout --new">
                {selectMarkerTonyaItem?.kojins?.some((e) => e.notifyHikitoriFlg === "1") && (
                  <>
                    {selectMarkerTonyaItem?.isHikitoriCheck === "1" ? (
                      <span class="ico --l --exclamation txt --fz-m --bold">引取依頼確認中</span>
                    ) : (
                      <a
                        href="#"
                        className="btn --secondary jsShowHikitoriirai"
                        onClick={(e) => {
                          e.preventDefault();
                          hikitoriModalControl.on();
                        }}
                        style={{ pointerEvents: selectMarkerTonyaItem?.isHikitoriCheck === "1" ? "none" : "auto" }}
                      >
                        <span>引取依頼を作成</span>
                      </a>
                    )}
                  </>
                )}
              </div>
              <div className="collection-place --border">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                    selectMarkerTonyaItem?.teikis,
                    youbiList
                  )} ]`}</div>
                  <div className="ico --s --location">{selectMarkerTonyaItem?.jusho}</div>
                  <div className="ico --s --tel">{selectMarkerTonyaItem?.tel}</div>
                  <div className="ico --s --driver">{selectMarkerTonyaItem?.tanto}</div>
                </div>
                <div className="collection-place__pic --noimage" hidden></div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, selectMarkerTonyaItem?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    <span></span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>{selectMarkerTonyaItem?.user?.hokanGendo?.toLocaleString() || "－"}枚</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span>{getMeishoName(niyakuList, selectMarkerTonyaItem?.user?.niyakuKbn)}</span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span>{selectMarkerTonyaItem?.user?.biko}</span>
                </div>
              </div>
              <div className="tab --tonya">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a
                      href="javascript:void(0);"
                      className={tonyaPaneTab === 0 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTonyaPaneTab(0);
                      }}
                    >
                      出荷元
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={tonyaPaneTab === 1 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTonyaPaneTab(1);
                      }}
                    >
                      最近の回収実績
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={tonyaPaneTab === 2 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTonyaPaneTab(2);
                      }}
                    >
                      入出庫履歴
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table" style={{ display: tonyaPaneTab === 0 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    {selectMarkerTonyaItem?.shukkamotos?.map((shukkamoto, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{shukkamoto.tokuisakiName}</td>
                        <td>{shukkamoto.shohinName}</td>
                        <td className="ta--r">{shukkamoto.mc}</td>
                        <td className="ta--r">{shukkamoto.pc}</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: tonyaPaneTab === 1 ? "table" : "none" }}>
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    {selectMarkerTonyaItem?.kaishujissekis?.map((kaishujisseki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{formatDate(kaishujisseki.kaishuBi, "M/D(ddd)")}</td>
                        <td>{kaishujisseki.unsoName}</td>
                        <td>{kaishujisseki.jippiUnchinSuu.toLocaleString()}</td>
                        <td className="ta--r">{kaishujisseki.kaishuKingaku.toLocaleString()}円</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: tonyaPaneTab === 2 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    {selectMarkerTonyaItem?.nyushukkorirekis?.map((nyushukkorireki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{formatDate(nyushukkorireki.shoribi, "M/D(ddd)")}</td>
                        <td className="ta--r">{nyushukkorireki.nyukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{nyushukkorireki.shukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{nyushukkorireki.zaikoSuu.toLocaleString()}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pane__footer">
          {/* <div className="btn-layout --center">
            <a
              href="#"
              className="btn --primary paneOpen"
              rel="shinkichumon"
              onClick={(e) => onClickNewChumon(e, selectMarkerTonyaItem)}
            >
              <span>注文に追加</span>
            </a>
          </div> */}
        </div>
      </div>
      <div className={`pane pane--left-down pane--depo-left-down ${isViewDepoLeftDown && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --depo">デポ</div>
            <a
              href="#"
              className="hdg__close paneClose mapClose"
              rel="depo-left-down"
              onClick={(e) => {
                e.preventDefault();
                setSelectMarkerDepoItem(null);
                setViewDepoLeftDown(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{selectMarkerDepoItem?.name}</div>
              </div>
              <div className="requests --normal --fz-s">
                <div className="request">
                  <div className="request__title">住所</div>
                  <div className="request__value">{selectMarkerDepoItem?.jusho}</div>
                </div>
                <div className="request">
                  <div className="request__title">電話番号</div>
                  <div className="request__value">{selectMarkerDepoItem?.tel}</div>
                </div>
                <div className="request">
                  <div className="request__title">営業時間</div>
                  <div className="request__value">{selectMarkerDepoItem?.eigyoJikan}</div>
                </div>
                <div className="request">
                  <div className="request__title">休日対応</div>
                  <div className="request__value">{selectMarkerDepoItem?.kyujitsuTaio}</div>
                </div>
                <div className="request">
                  <div className="request__title">洗浄機能</div>
                  <div className="request__value">{selectMarkerDepoItem?.senjo}</div>
                </div>
                <div className="request">
                  <div className="request__title">検品数</div>
                  <div className="request__value">{selectMarkerDepoItem?.kenpin}</div>
                </div>
                <div className="request">
                  <div className="request__title">洗浄数</div>
                  <div className="request__value">{selectMarkerDepoItem?.senjosu}</div>
                </div>
              </div>
              <div className="note">
                <div>
                  <span>備考1：</span>
                  <span>{selectMarkerDepoItem?.depoBiko}</span>
                </div>
                <div>
                  <span>備考2：</span>
                  <span>{selectMarkerDepoItem?.psBiko}</span>
                </div>
              </div>
              <div className="owned-pallets">
                <div className="owned-pallets__title">現在の保管枚数</div>
                <div className="owned-pallets__body">
                  <div>
                    <span>
                      <span>{selectMarkerDepoItem?.zaikoSuryo?.toLocaleString() || "－"}</span>
                      枚/
                      {selectMarkerDepoItem?.hoyuKanoSuu?.toLocaleString() || "－"}枚
                    </span>
                    <span>{`(${
                      !selectMarkerDepoItem || hoyuritu === 0 ? "－" : hoyuritu?.toLocaleString() || "－"
                    }%)`}</span>
                  </div>
                  <div>
                    <span>
                      <span
                        style={{
                          width: hoyuritu >= 100 ? 100 + "%" : (hoyuritu || 0) + "%",
                        }}
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="tab --depo">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a href="javascript:void(0);" className="--current">
                      今後１週間の予定
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫予定数</th>
                      <th>出庫予定数</th>
                      <th>予定保管枚数(使用率)</th>
                    </tr>
                    {selectMarkerDepoItem?.rirekis?.map((rireki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{formatDate(rireki.shoribi, "M/D(ddd)")}</td>
                        <td className="ta--r">{rireki.nyukoYoteiSuu.toLocaleString()}</td>
                        <td className="ta--r">{rireki.shukoYoteiSuu.toLocaleString()}</td>
                        <td className="ta--r">
                          {rireki.yoteiHokanSuu.toLocaleString()}({rireki.siyouritu.toLocaleString()}%)
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pane__footer">
          <div className="btn-layout --center">
            {/* 一旦left-downからは操作無 */}
            {/* <a
              href="javascript:void(0);"
              className="btn --primary paneOpen"
              rel="shinkichumon"
              onClick={(e) => {
                e.preventDefault();
                setLastDepo(selectMarkerDepoItem);
                setViewDepoLeftDown(false);
                setViewNewChumon(true);
              }}
            >
              <span>入庫先に指定</span>
            </a> */}
          </div>
        </div>
      </div>
      <div className={`pane pane--left-down pane--teikikaishu-left-down ${isViewTeikiKaishuLeftDown && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --teikikaishu">定期回収</div>
            <a
              href="#"
              className="hdg__close paneClose mapClose"
              rel="teikikaishu-left-down"
              onClick={(e) => {
                e.preventDefault();
                setSelectMarkerTeikiItem(null);
                setViewTeikiKaishuLeftDown(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{selectMarkerTeikiItem?.name}</div>
                <div className="title__zaiko">
                  現在の在庫
                  <span>{selectMarkerTeikiItem?.zaikoSuryo?.toLocaleString() || "－"}</span>枚
                </div>
              </div>
              <div className="collection-place">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                    selectMarkerTeikiItem?.teikis,
                    youbiList
                  )} ]`}</div>
                  <div className="ico --s --location">{selectMarkerTeikiItem?.jusho}</div>
                  <div className="ico --s --tel">{selectMarkerTeikiItem?.tel || "-"}</div>
                  <div className="ico --s --driver">{selectMarkerTeikiItem?.tanto || "-"}</div>
                </div>
                <div className="collection-place__pic --noimage">
                  {/* <a href="javascript:void(0);" className="jsShowPicture">
                    <img src="https://dummyimage.com/140x96/ccc/fff" alt="" />
                  </a> */}
                </div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, selectMarkerTeikiItem?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    <span></span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>{selectMarkerTeikiItem?.hoyuKanoSuu || "-"}</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span></span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span>{selectMarkerTeikiItem?.biko || "-"}</span>
                </div>
              </div>
              <div className="tab --teikikaishu">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a
                      href="javascript:void(0);"
                      className={teikiPaneTab === 0 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTeikiPaneTab(0);
                      }}
                    >
                      出荷元
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={teikiPaneTab === 1 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTeikiPaneTab(1);
                      }}
                    >
                      最近の回収実績
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={teikiPaneTab === 2 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTeikiPaneTab(2);
                      }}
                    >
                      入出庫履歴
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table" style={{ display: teikiPaneTab === 0 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    {teikiShosai?.shukkamotos?.map((shukkamoto, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{shukkamoto.tokuisakiName}</td>
                        <td>{shukkamoto.shohinName}</td>
                        <td className="ta--r">{shukkamoto.mc}</td>
                        <td className="ta--r">{shukkamoto.pc}</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: teikiPaneTab === 1 ? "table" : "none" }}>
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    {teikiShosai?.kaishujissekis?.map((kaishujisseki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{formatDate(kaishujisseki.kaishuBi, "M/D(ddd)")}</td>
                        <td>{kaishujisseki.unsoName}</td>
                        <td>{kaishujisseki.jippiUnchinSuu.toLocaleString()}</td>
                        <td className="ta--r">{kaishujisseki.kaishuKingaku.toLocaleString()}円</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: teikiPaneTab === 2 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    {teikiShosai?.nyushukkorirekis?.map((nyushukkorireki, index) => (
                      <tr key={index.toString()} className="tab__tr">
                        <td>{formatDate(nyushukkorireki.shoribi, "M/D(ddd)")}</td>
                        <td className="ta--r">{nyushukkorireki.nyukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{nyushukkorireki.shukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{nyushukkorireki.zaikoSuu.toLocaleString()}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--full pane--chikakunohaisha ${isViewNearHaisha && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text">近くの配車を探す</div>
            <a
              href="#"
              className="hdg__close paneClose"
              rel="chikakunohaisha"
              onClick={(e) => {
                e.preventDefault();
                setViewNearhaisha(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout --col2">
            <div>
              <div className="pane-layout__inner">
                <div className="title --border">
                  <div className="title__title">{selectMarkerIraiItem?.name}</div>
                  <div className="title__zaiko">
                    現在の在庫
                    <span>{selectMarkerIraiItem?.zaikoSuryo?.toLocaleString()}</span>枚
                  </div>
                </div>
                <div className="requests --normal">
                  <div className="request">
                    <div className="request__title">回収パレット数</div>
                    <div className="request__value">
                      <span>{selectMarkerIraiItem?.number?.toLocaleString()}</span>枚
                    </div>
                  </div>
                  <div className="request">
                    <div className="request__title">回収希望日</div>
                    <div className="request__value">{selectMarkerIraiItem?.request}</div>
                  </div>
                  <div className="request">
                    <div className="request__title">回収希望時間</div>
                    <div className="request__value">{selectMarkerIraiItem?.time}</div>
                  </div>
                  <div className="request">
                    <div className="request__title">パレットの種類</div>
                    <div className="request__value">{selectMarkerIraiItem?.pallet}</div>
                  </div>
                  <div className="request">
                    <div className="request__title">注意事項</div>
                    <div
                      className="request__value"
                      dangerouslySetInnerHTML={{
                        __html: selectMarkerIraiItem?.biko?.replace(/\n/g, "<br />"),
                      }}
                    />
                  </div>
                  <div className="request">
                    <div className="request__title ico --milnet">MILNET番号</div>
                    <div className="request__value">{selectMarkerIraiItem?.ido?.denpyoNo}</div>
                  </div>
                  <div className="request">
                    <div className="request__title">回収店備考</div>
                    <div
                      className="request__value"
                      dangerouslySetInnerHTML={{ __html: selectMarkerIraiItem?.biko2?.replace(/\n/g, "<br />") }}
                    />
                  </div>
                </div>
                <div className="collection-place --border">
                  <div className="collection-place__text">
                    <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                      selectMarkerIraiItem?.teikis,
                      youbiList
                    )} ]`}</div>
                    <div className="ico --s --location">{selectMarkerIraiItem?.jusho}</div>
                    <div className="ico --s --tel">{selectMarkerIraiItem?.tel || "－"}</div>
                    <div className="ico --s --driver">{selectMarkerIraiItem?.tanto || "－"}</div>
                  </div>
                  <div className={`collection-place__pic ${selectMarkerIraiItem?.files?.length === 0 && "--noimage"}`}>
                    {selectMarkerIraiItem?.files?.length > 0 && (
                      <a href="javascript:void(0);" className="jsShowPicture">
                        <img src={selectMarkerIraiItem?.files[0]?.url} alt="" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="note">
                  <div>
                    <div>
                      <span>回収可能曜日：</span>
                      <span>{getMeishoName(youbiList, selectMarkerIraiItem?.user?.kaishuYoubiKbns)}</span>
                    </div>
                    <div>
                      <span>回収可能時間：</span>
                      <span>
                        {getDisplayKiboTime(
                          selectMarkerIraiItem?.user?.kaishuTimeFrom,
                          selectMarkerIraiItem?.user?.kaishuTimeTo
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>保有限度枚数：</span>
                      <span>{selectMarkerIraiItem?.user?.hokanGendo}枚</span>
                    </div>
                    <div hidden>
                      <span>荷役：</span>
                      <span>{getMeishoName(niyakuList, selectMarkerIraiItem?.user?.niyakuKbn)}</span>
                    </div>
                  </div>
                  <div>
                    <span>注意事項：</span>
                    <span
                      className="confirm-layout"
                      dangerouslySetInnerHTML={{
                        __html: selectMarkerIraiItem?.user?.biko?.replace(/\n/g, "<br />"),
                      }}
                    />
                  </div>
                </div>
                <div className="tab --kaishuirai">
                  <div className="tab__header">
                    <div className="tab__menu">
                      <a
                        href="javascript:void(0);"
                        className={kaishuIraiPaneTab === 0 && "--current"}
                        onClick={(e) => {
                          e.preventDefault();
                          setKaishuIraiPaneTab(0);
                        }}
                      >
                        出荷元
                      </a>
                      <a
                        href="javascript:void(0);"
                        className={kaishuIraiPaneTab === 1 && "--current"}
                        onClick={(e) => {
                          e.preventDefault();
                          setKaishuIraiPaneTab(1);
                        }}
                      >
                        最近の回収実績
                      </a>
                      <a
                        href="javascript:void(0);"
                        className={kaishuIraiPaneTab === 2 && "--current"}
                        onClick={(e) => {
                          e.preventDefault();
                          setKaishuIraiPaneTab(2);
                        }}
                      >
                        入出庫履歴
                      </a>
                    </div>
                  </div>
                  <div className="tab__body">
                    <table
                      className="tab__table"
                      style={{
                        display: kaishuIraiPaneTab === 0 ? "table" : "none",
                      }}
                    >
                      <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col className="" />
                      </colgroup>
                      <tr className="tab__th">
                        <th>会社名</th>
                        <th>商品</th>
                        <th>MC</th>
                        <th>PC</th>
                      </tr>
                      {selectMarkerIraiItem?.shukkamotos?.map((item, index) => (
                        <tr className="tab__tr" key={"shukkamoto-" + index.toString()}>
                          <td>{item.tokuisakiName}</td>
                          <td>{item.shohinName}</td>
                          <td className="ta--r">{item.mc}</td>
                          <td className="ta--r">{item.pc}</td>
                        </tr>
                      ))}
                    </table>
                    <table
                      className="tab__table"
                      style={{
                        display: kaishuIraiPaneTab === 1 ? "table" : "none",
                      }}
                    >
                      <colgroup>
                        <col />
                        <col style={{ width: "40%" }} />
                        <col style={{ width: "25%" }} />
                      </colgroup>
                      <tr className="tab__th">
                        <th>回収日</th>
                        <th>運送会社</th>
                        <th>回収単位</th>
                      </tr>
                      {selectMarkerIraiItem?.kaishujissekis?.map((item, index) => (
                        <tr className="tab__tr" key={"kaishujissekis-" + index.toString()}>
                          <td>{item.kaishuBi}</td>
                          <td>中部急送</td>
                          <td className="ta--r">478円/枚</td>
                        </tr>
                      ))}
                    </table>
                    <table
                      className="tab__table"
                      style={{
                        display: kaishuIraiPaneTab === 2 ? "table" : "none",
                      }}
                    >
                      <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col className="" />
                      </colgroup>
                      <tr className="tab__th">
                        <th>日付</th>
                        <th>入庫</th>
                        <th>回収</th>
                        <th>在庫</th>
                      </tr>
                      {selectMarkerIraiItem?.nyushukkorirekis?.map((item, index) => (
                        <tr className="tab__tr" key={"nyushukkorirekis-" + index.toString()}>
                          <td>{formatDate(item.shoribi, "M/D(ddd)")}</td>
                          <td className="ta--r">{item.nyukoSuu.toLocaleString()}</td>
                          <td className="ta--r">{item.shukoSuu.toLocaleString()}</td>
                          <td className="ta--r">{item.zaikoSuu.toLocaleString()}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="--bg-gray p--0">
              <div className="pane-layout__inner">
                <div className="search --center p--0">
                  <div className="search__condition">
                    <div className="condition">
                      <div className="condition__date">
                        <DayPicker hasArrow day={searchDayHaisha} setDay={setSearchDayHaisha} />
                      </div>
                      <div className="condition__freeword ml--15">
                        <input
                          type="text"
                          className="w--175 ico --search --s --offset"
                          placeholder="回収店・商品・作成者名"
                          value={searchTextHaisha}
                          onChange={(e) => setSearchTextHaisha(e.target.value)}
                        />
                      </div>
                      <div className="condition__btn">
                        <a href="#" className="btn --secondary --h-s w--50" onClick={onSearchHaisha}>
                          <span>検索</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filters --border">
                  <div className="filter">
                    <div className="filter__title">状態</div>
                    <div className="filter__value">
                      <a href="#" className="--selected">
                        {`すべて(${binStatusWithTonya?.allNum?.toLocaleString() ?? "0"})`}
                      </a>
                      <a href="#">{`下書き(${binStatusWithTonya?.sitagakiNum?.toLocaleString() ?? "0"})`}</a>
                      <a href="#">{`依頼中(${binStatusWithTonya?.iraityuNum?.toLocaleString() ?? "0"})`}</a>
                      <a href="#">{`確定済(${binStatusWithTonya?.kakuteizumiNum?.toLocaleString() ?? "0"})`}</a>
                    </div>
                  </div>
                </div>
                <div className="haishas">
                  {/* TODO bin rendering */}
                  {/* listHaisha */}
                  {[{ id: 1, meisais: [{ id: 1 }, { id: 2 }, { id: 3 }] }]?.map((haisha) => (
                    <div key={haisha.id} className="haisha">
                      <div className="haisha__header">
                        {/* --iraichuでbackground:yellow */}
                        <div className="summary --w-s --iraichu">
                          <div>
                            <div className="summary__status">
                              {/* <div className="status --shitagaki">
                                <span>下書き | 未送信</span>
                              </div> */}
                              {/* <div className="status --attention-o">
                                <span>下書き | 要依頼</span>
                              </div> */}
                              {/* <div className="status --attention">
                                <span>下書き | 回収店NG</span>
                              </div> */}
                              <div className="status --iraichu">
                                <span>依頼中</span>
                              </div>
                              {/* <div className="status --attention">
                                <span>依頼中 | 配送NG</span>
                              </div> */}
                            </div>
                            <div className="summary__date">6/24(木) 10:00～</div>
                            <div className="summary__detail">
                              <a
                                href="#"
                                className="link --secondary --fz-ss --bold paneOpen"
                                rel="chumonshosai"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setBinId(haisha.id);
                                  setViewBinShosaiPane(true);
                                }}
                              >
                                詳細
                              </a>
                            </div>
                          </div>
                          <div>
                            <div className="summary__load">
                              <span className="ico --truck-4t-green --s --bold --fz-s">45%</span>
                              {/* <span className="ico --truck-4t-red --s --bold --fz-s">45%</span> */}
                              {/* <span className="ico --truck-10t-green --s --bold --fz-s">45%</span> */}
                              {/* <span className="ico --truck-10t-red --s --bold --fz-s">45%</span> */}
                            </div>
                            <div className="summary__distance">
                              距離：<span>166km　中部運送</span>
                            </div>
                            <div className="summary__fare">
                              <span className="t4">希望運賃：3,000円</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="haisha__body">
                        <div className="haisha__waypoint">
                          <div className="waypoint">
                            <table className="waypoint__table">
                              {haisha.meisais?.map((meisai, index) => (
                                <tr key={meisai.id} className="waypoint__row">
                                  <td className="waypoint__num">
                                    <span
                                      className={`num --s --${
                                        haisha.meisais.length === index + 1 ? "goal" : "kaishuirai"
                                      }`}
                                    >
                                      {haisha.meisais.length === index + 1 ? "" : index + 1}
                                    </span>
                                  </td>
                                  <td className="waypoint__cell">
                                    <span>6/24(木) 10:00-12:00</span>
                                  </td>
                                  <td className="waypoint__cell">
                                    <span>75枚</span>
                                  </td>
                                  <td className="waypoint__cell">
                                    <span>PALSAK名古屋</span>
                                  </td>
                                  <td className="waypoint__cell">
                                    <span>ここに住所がはいります。</span>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BinShosaiPane
        onMoveMapCenter={mapRef.current?.changeCenter}
        onMapReload={mapRef.current?.reload}
        onDetail={onClickDetail}
        binId={binId}
        setBinId={setBinId}
        gridList={gridList}
        createTable={createTable}
      />
      <KaishuIraiLeftUpPane
        isOpen={isViewKaishuIraiLeftUp}
        setOpen={setViewkaishuIraiLeftUp}
        item={selectMarkerIraiItem}
        setItem={setSelectMarkerIraiItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
        onOpenPictureModal={onOpenPictureModal}
      />
      <TonyaLeftUpPane
        isOpen={isViewTonyaLeftUp}
        setOpen={setViewTonyaLeftUp}
        item={selectMarkerTonyaItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <DepoLeftUpPane
        isOpen={isViewDepoLeftUp}
        setOpen={setViewDepoLeftUp}
        item={selectMarkerDepoItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <TeikiKaishuLeftUp
        isOpen={isViewTeikiKaishuLeftUp}
        setOpen={setViewTeikiKaishuLeftUp}
        item={selectMarkerTeikiItem}
      />
      {/* 引取 */}
      <HikitoriModal
        {...{
          item: selectMarkerIraiItem?.subType === "hikitori" ? selectMarkerIraiItem : selectMarkerTonyaItem,
          setViewTonyaLeftDown,
          setViewTonyaLeftUp,
          setViewKaishuIraiLeftDown,
          setViewkaishuIraiLeftUp,
          setSelectMarkerIraiItem,
        }}
      />
      {/* dialog */}
      <PictureModal isOpen={isPictureModal} control={pictureModalControl} item={selectMarkerIraiItem} />
      <DeleteModal isOpen={isDeleteModal} control={deleteModalControl} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={message} />
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={message} onOk={refOk.current} />
    </div>
  );
}
